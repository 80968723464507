import {
  initAnimatedPlaceholderFormField,
  sendBrowserAgnosticEvent,
  storageAvailable,
  trackCustomUserAction,
} from "../core/utils";

/**
 * Class managing the behavior of a page-exit popup.
 */
export default class ExitPopup {
  /**
   * The constructor for the class. Sets up state and event listeners.
   *
   * @param {HTMLElement} root - the root element of the exit popup (usually an
   *     .exit-popup-container)
   * @param {boolean} showAutomaticallyOnDelay - if True, the popup will show
   *     automatically after a delay, regardless of page scroll state.
   * @param {object|null} storage - the instance of browser storage (local or
   *     otherwise) to use. If not provided, we'll use `window.localStorage`
   */
  constructor(root, showAutomaticallyOnDelay = false, storage = null) {
    this.root = root;
    if (!this.root) {
      return;
    }

    // Configure which storage to use.
    if (storage) {
      this.storage = storage;
    } else if (storageAvailable("localStorage")) {
      this.storage = window.localStorage;
    }

    // For the purposes of this component we're determining being "on mobile" as
    // having a window width < 768px;
    this.isMobile = window.innerWidth < 768;

    // Lots of configuration is provided via data-attributes:
    // data-exit-popup-storage-key: the key to store state data in, in
    // local(or other)Storage
    this.storageKey = this.root.dataset.exitPopupStorageKey;
    // data-exit-popup-session-key: the key to store state about this particular
    // "instance" of the exit popup's state.
    this.sessionKey = this.root.dataset.exitPopupSessionKey;

    // These ones are for GA-related values sent with GA events.
    // `this.context` is for scoping the label by containing page, generally.
    this.eventLabel = this.root.dataset.exitPopupEventLabel || this.storageKey;
    this.eventCategory = this.root.dataset.exitPopupEventCategory || "exit-popup";
    this.context = this.root.dataset.exitPopupContext;

    // Internal state.
    this.canShow = false;
    this.isShowing = false;
    this.hasShownInLifecycle = false;

    // Determine if we need to create interaction (scroll, focus-loss, etc) listeners.
    const createInteractionListeners =
      !this.getHasBeenShown() && !this.root.dataset.exitPopupManualOnly;

    // Wrapper around setting this.canShow to true, which also removes interaction
    // listeners for performance's sake
    this.setCanShowListener = () => {
      this.canShow = true;
      this.removeInteractionListeners();
    };

    // Listener for browser loss-of-focus, attached to events below as necessary.
    // We store this in a var so we can use it as an argument to removeEventListener
    // later.
    this.blurListener = () => {
      if (this.canShow && !this.hasShownInLifecycle) {
        this.show();
      }
    };

    if (this.isMobile) {
      /*
        Mobile display logic:
         - Enable display once we've scrolled past 3/4 of a screen's worth of content
         - Display is triggered by:
            1. Scroll back to within 10px of top of page.
            2. On window `blur` event.
        - If constructor param is provided, a 10.5 second delay from init will trigger
          display as well.
       */
      if (createInteractionListeners) {
        this.mobileScrollHandler = () => {
          if (this.canShow) {
            // We're gonna check to see if we've scrolled "back" to top of screen
            if (window.scrollY < 10) {
              if (this.canShow && !this.hasShownInLifecycle) {
                this.show();
              }
            }
          } else if (window.scrollY > window.innerHeight * 0.75) {
            // If we've scrolled down 3/4 of a screen's worth, then we can activate the
            // scroll-back checking.
            this.canShow = true;
          }
        };

        document.addEventListener("scroll", this.mobileScrollHandler);
      }

      if (showAutomaticallyOnDelay && createInteractionListeners) {
        setTimeout(() => {
          if (!this.hasShownInLifecycle) {
            this.canShow = true;
            this.show();
          }
        }, 10500);
      }
    } else {
      /*
        Desktop display logic:
        - Whichever comes first, a 10 second delay from init or first-scroll, will
          enable display.
        - Once enabled, display is triggered by:
           1. Mouse leaving the window
           2. Window receiving `blur` event.
        - If constructor param is provided, a 10.5 second delay from init will trigger
          display as well.
       */
      if (createInteractionListeners) {
        this.interactionDelayTimeout = setTimeout(this.setCanShowListener, 10000);

        document.addEventListener("scroll", this.setCanShowListener);

        setTimeout(() => {
          document.documentElement.addEventListener("mouseleave", () => {
            if (this.canShow && !this.hasShownInLifecycle) {
              this.show();
            }
          });
        }, 500);

        if (showAutomaticallyOnDelay) {
          setTimeout(() => {
            if (!this.hasShownInLifecycle) {
              this.canShow = true;
              this.show();
            }
          }, 10500);
        }
      }

      if (!root.dataset.exitPopupDisableBackgroundDismiss) {
        this.root.addEventListener("mousedown", (event) => {
          if (event.target === this.root) {
            event.stopPropagation();
            this.hide();
          }
        });
      }
    }

    if (createInteractionListeners) {
      // The blur event listener (valid for both mobile and desktop)
      window.addEventListener("blur", this.blurListener);
    }

    // Grab and report conversions from the submit buttons responsible for them.
    this.root
      .querySelectorAll('[data-exit-popup-conversion="true"]')
      .forEach((conversionTrigger) => {
        conversionTrigger.addEventListener("click", () => {
          this.reportEvent("conversion");
        });
      });

    // Handle popup close.
    this.root.querySelectorAll(".close").forEach((closeButton) => {
      closeButton.addEventListener("click", () => {
        this.hide();
      });
    });

    // For the HX-swapped form elements, we need to reinit the animatedPlaceholder
    // behavior, and to reset the form submit-protect behavior.
    document.addEventListener("htmx:afterSettle", (e) => {
      if (e.detail.elt.classList.contains("business-user-guidance")) {
        e.detail.elt
          .querySelectorAll(
            ".el-animated-placeholder-label-input input,textarea,select",
          )
          .forEach(initAnimatedPlaceholderFormField);

        const form = this.root.querySelector("form");
        if (form) {
          sendBrowserAgnosticEvent(form, "cancel-submit-protect");
        }
      }
    });

    // Add some event handling for external use.
    document.body.addEventListener("elep:show", () => {
      this.show();
    });

    document.body.addEventListener("elep:hide", () => {
      this.hide();
    });

    document.body.addEventListener("elep:disable", () => {
      this.canShow = false;
    });

    // Disable once a form field gets focus.
    document.querySelectorAll("input,select").forEach((inp) => {
      inp.addEventListener("focus", () => {
        this.canShow = false;
      });
    });
  }

  removeInteractionListeners() {
    if (this.setCanShowListener) {
      document.removeEventListener("scroll", this.setCanShowListener);
    }

    if (this.blurListener) {
      document.removeEventListener("blur", this.blurListener);
    }

    if (this.mobileScrollHandler) {
      document.removeEventListener("scroll", this.mobileScrollHandler);
    }

    if (this.interactionDelayTimeout) {
      clearTimeout(this.interactionDelayTimeout);
    }
  }

  /**
   * Return if, in this session, we have shown the exit popup.
   * SessionKey value is checked to see if it's a timestamp in the past (or not existing)
   * in which case we have NOT been shown. StorageKey value is just boolean.
   *
   * @returns {boolean} - if we have been shown in this session.
   */
  getHasBeenShown() {
    let sessionShown = false;

    if (this.storage) {
      if (this.sessionKey) {
        const now = Math.floor(Date.now() / 1000);
        const val = this.storage.getItem(this.sessionKey);

        if (val) {
          try {
            const parsedVal = JSON.parse(val);
            if (typeof parsedVal === "number" && now < parsedVal) {
              sessionShown = true;
            }
          } catch {
            sessionShown = false;
          }
        }
      }

      if (this.storageKey) {
        return this.storage.getItem(this.storageKey) || sessionShown;
      }

      return false;
    }

    return sessionShown;
  }

  /**
   * Set the has-been-shown value in both the storageKey value and the sessionKey value
   * as available. StorageKey is a 1/0 boolean, sessionKey is a timestamp one week from now
   */
  setHasBeenShown() {
    if (this.storage) {
      if (this.storageKey) {
        this.storage.setItem(this.storageKey, "1");
      }

      if (this.sessionKey) {
        const oneWeekFromNow = Math.floor(Date.now() / 1000) + 86400 * 7;
        this.storage.setItem(this.sessionKey, oneWeekFromNow);
      }
    }
  }

  /**
   * Show the exit popup.
   *
   * @param {boolean} force - If true, show regardless of canShow/hasShown
   * @param {boolean} sendShownEvent - If true, send the shown event to document.body
   */
  show(force = false, sendShownEvent = true) {
    if (((this.canShow && !this.hasShownInLifecycle) || force) && !this.isShowing) {
      this.isShowing = true;
      this.hasShownInLifecycle = true;
      this.root.classList.remove("d-none");
      this.reportEvent("display");
      this.setHasBeenShown();

      if (sendShownEvent) {
        sendBrowserAgnosticEvent(document.body, "elep:shown");
      }
      this.removeInteractionListeners();
    }
  }

  /**
   * Hide the exit popup.
   */
  hide() {
    if (this.isShowing) {
      this.isShowing = false;
      this.canShow = false;
      this.root.classList.add("d-none");
      this.reportEvent("hide");

      sendBrowserAgnosticEvent(document.body, "elep:hidden");
    }
  }

  /**
   * Report an event to the GTM/GA dataLayer
   *
   * @param {string} eventName - the event name
   */
  reportEvent(eventName) {
    trackCustomUserAction(
      `${this.eventLabel}:${this.context}`,
      this.eventCategory,
      eventName,
    );
  }
}
