import config from "./config";
import { sendBrowserAgnosticEvent } from "./utils";

/**
 * Initialize the reCAPTCHAs for the page.
 *
 * Special note for here: any HTMX-powered forms that rely on ReCAPTCHA must switch
 * their hx-trigger to `hx-recaptcha-submit` so that they do not precede these handlers
 * when attempting to handle `submit` events via the default hx-post behavior.
 */
export default function initReCAPTCHAs() {
  document.querySelectorAll(".g-recaptcha-button-submit").forEach((element) => {
    if (!element.dataset.recaptchaInitialized) {
      element.addEventListener("click", () => {
        window.grecaptcha
          .execute(config.reCAPTCHASiteKey, { action: "submit" })
          .then((token) => {
            const form = element.closest("form");
            form.querySelector('input[name="g-recaptcha-response"]').value = token;
            if (form.checkValidity()) {
              if (form.getAttribute("hx-post")) {
                sendBrowserAgnosticEvent(form, "hx-recaptcha-submit");
              } else {
                form.requestSubmit();
              }
            }
          });
      });

      element.setAttribute("data-recaptcha-initialized", "1");
    }
  });

  document.querySelectorAll(".g-recaptcha-form").forEach((formElement) => {
    if (!formElement.dataset.recaptchaInitialized) {
      formElement.addEventListener("submit", (event) => {
        event.stopPropagation();
        event.preventDefault();

        window.grecaptcha
          .execute(config.reCAPTCHASiteKey, { action: "submit" })
          .then((token) => {
            formElement.querySelector('input[name="g-recaptcha-response"]').value =
              token;

            if (formElement.hasAttribute("novalidate") || formElement.checkValidity()) {
              if (formElement.getAttribute("hx-post")) {
                sendBrowserAgnosticEvent(formElement, "hx-recaptcha-submit");
              } else {
                formElement.submit();
              }
            }
          });
      });

      formElement.setAttribute("data-recaptcha-initialized", "1");
    }
  });
}
