import { isVisible } from "../core/utils";

export default async function initGoogleAutocomplete(props) {
  if (!props.element) {
    // Exit early if we don't have the element.
    return null;
  }
  const { Autocomplete } = await window.google.maps.importLibrary("places");

  const autocomplete = new Autocomplete(props.element, props.options);

  props.element.addEventListener("keydown", (event) => {
    // TODO(eric): ideally, I'd like this to be simple but it doesn't seem to
    // work for the dynamically added forms on the edit events page?
    if (props.alwaysBlockReturnKeySubmit) {
      if (event.keyCode === 13) {
        event.preventDefault();
      }
    } else if (event.keyCode === 13) {
      const pacContainer = document.querySelector(".pac-container");
      if (pacContainer && isVisible(pacContainer)) {
        event.preventDefault();
      }
    }
  });

  if (props.metadataElement) {
    window.google.maps.event.addListener(autocomplete, "place_changed", () => {
      const { metadataElement } = props;
      const place = autocomplete.getPlace();

      if (place.formatted_address && place.geometry && place.place_id) {
        const metadata = {
          address_components: place.address_components,
          formatted_address: place.formatted_address,
          name: place.name,
          location: place.geometry.location.toJSON(),
          place_id: place.place_id,
          types: place.types,
          url: place.url,
        };
        metadataElement.value = JSON.stringify(metadata);
      }
    });
  }

  return autocomplete;
}
