/**
 * Internal function for initializing a single dropdown.
 */

export function initEditDropdown(editIcon) {
  editIcon.addEventListener("click", () => {
    const editMenu = editIcon.parentElement.querySelector(".edit-menu-dropdown");
    if (editMenu) {
      editMenu.classList.toggle("d-none");
    }
  });
}

/**
 * Function that initializes edit icons to have a click listener to open a dropdown
 * menu with edit actions.
 */
export function initEditDropdowns() {
  // If click out of opened edit-menu-dropdown, then close menu.
  document.addEventListener("click", (event) => {
    const editMenus = document.querySelectorAll(".edit-menu-dropdown");
    const currentClick = event.target;

    // Capture if we're opening a menu. Sibling or first-cousin node.
    const currentEditMenu =
      (currentClick.parentElement &&
        currentClick.parentElement.querySelector(".edit-menu-dropdown")) ||
      (currentClick.parentElement &&
        currentClick.parentElement.parentElement &&
        currentClick.parentElement.parentElement.querySelector(".edit-menu-dropdown"));

    if (!currentEditMenu) {
      editMenus.forEach((menu) => menu.classList.add("d-none"));
    } else {
      editMenus.forEach((menu) => {
        if (menu !== currentEditMenu) {
          menu.classList.add("d-none");
        }
      });
    }
  });

  // Open and close edit menus on edit icon click
  const editIcons = document.querySelectorAll(".toggle-dropdown-edit-menu");
  editIcons.forEach(initEditDropdown);
}
