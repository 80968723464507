/**
 * Function that initializes expansion behavior on an element. Meant to be used with the
 * `expandable` template tag in Django.
 *
 * @param {Element} element - DOM Node to initialize expander behavior on.
 */
export default function initExpander(element) {
  const readMoreDetails = element.querySelector(".read-more-details");
  const readMore = element.querySelector(".read-more");

  if (!readMoreDetails || !readMore) {
    return;
  }

  element.querySelector(".read-more-link").addEventListener("click", () => {
    readMore.classList.add("d-none");
    readMoreDetails.classList.remove("d-none");
  });

  element.querySelector(".read-less-link").addEventListener("click", () => {
    readMoreDetails.classList.add("d-none");
    readMore.classList.remove("d-none");
  });
}
