import Collapse from "bootstrap/js/dist/collapse";

import initExpander from "./Expander";
import { animateCSS, fadeOut, handleAjaxFormSubmitProtection } from "../core/utils";
import requests, { ResponseError } from "../core/requests";

/**
 * Function that initializes truncated user posts and appends a "read more"
 * link that can expand the post to its full length (with a "read less" option).
 * Currently used for Community FAQ answers and Comments
 */
export function initExpandableUserPosts() {
  document.querySelectorAll(".user-post .expandable").forEach((userPost) => {
    initExpander(userPost);
  });
}

export function initUserPostForm(userPostForm) {
  // Show/hide submit button on focus events of answer field.
  const answerInput = userPostForm.querySelector("textarea.answer-text");
  if (answerInput) {
    answerInput.addEventListener("focus", () => {
      // Show submit button.
      const submitWrapper = answerInput.parentElement.querySelector(
        ".collapsed-submit-wrapper",
      );
      if (submitWrapper) {
        Collapse.getOrCreateInstance(submitWrapper, { toggle: false }).show();
      }

      // Expand comment box.
      answerInput.classList.add("expanded-text");
    });

    answerInput.addEventListener("blur", () => {
      if (!answerInput.value) {
        // Hide submit button
        const submitWrapper = answerInput.parentElement.querySelector(
          ".collapsed-submit-wrapper",
        );
        if (submitWrapper) {
          Collapse.getOrCreateInstance(submitWrapper, { toggle: false }).hide();
        }

        // Contract comment box.
        answerInput.classList.remove("expanded-text");
      }
    });
  }

  // Disable submit button on submit.
  userPostForm.addEventListener("submit", () => {
    userPostForm.querySelectorAll(".btn").forEach((btn) => {
      btn.setAttribute("disabled", "disabled");
    });
  });
}

export function initUserPostForms() {
  initExpandableUserPosts();

  document.querySelectorAll(".user-post-form").forEach((userPostForm) => {
    initUserPostForm(userPostForm);
  });
}

export function initEditableUserPost(editablePost, props) {
  const mainEditControls = editablePost.querySelector(".main-edit-controls");
  let editButton = editablePost.querySelector(".edit-user-post");
  const confirmEditControlsContainer = editablePost.querySelector(".edit-confirmation");
  const editField = editablePost.querySelector(".user-post-edit-field");
  const confirmEditButton = editablePost.querySelector(".confirm-edit-user-post");
  const toggleEditDropdownIcon = editablePost.querySelector(
    ".toggle-dropdown-edit-menu",
  );
  const answerInput = editablePost.querySelector("textarea.answer-text");
  // Handle situation where we might be getting an edit button from a child
  // user-editable post.
  if (editButton && editButton.closest(".editable-user-post") !== editablePost) {
    editButton = null;
  }

  const showMainEditState = async (saved = false) => {
    if (mainEditControls) {
      mainEditControls.classList.remove("d-none");
    }

    if (confirmEditControlsContainer) {
      confirmEditControlsContainer.classList.add("d-none");
    }

    const deleteConfirmation = editablePost.querySelector(".delete-confirmation");
    if (deleteConfirmation) {
      deleteConfirmation.classList.add("d-none");
    }

    const userPostTextContainer = editablePost.querySelector(
      ".user-post-text-container",
    );
    if (userPostTextContainer) {
      userPostTextContainer.classList.remove("d-none");
    }

    const userPostText = editablePost.querySelector(".user-post-text");
    if (userPostText) {
      userPostText.classList.remove("d-none");
    }

    if (editField) {
      editField.classList.add("d-none");
    }

    toggleEditDropdownIcon.classList.remove("d-none");

    if (editablePost.classList.contains("post")) {
      editablePost.querySelector(".edit-post").classList.add("d-none");
      editablePost.querySelector(".post-actions").classList.remove("d-none");
      editablePost.querySelector(".post-content > p").classList.remove("d-none");
    } else if (editField) {
      editField.classList.add("d-none");
    }

    if (saved) {
      const savedMessage = editablePost.querySelector(".saved-message");
      if (savedMessage) {
        savedMessage.classList.remove("d-none");
        await animateCSS(savedMessage, "fadeIn", "200ms");
        fadeOut(savedMessage, 2000);
      }
    }
  };

  // Submit editable posts when user presses 'return'
  if (answerInput) {
    answerInput.addEventListener("keydown", (evt) => {
      if (evt.keyCode === 13 && !evt.metaKey) {
        answerInput.closest("form.user-post-form").submit();
        return false;
      }

      return true;
    });
  }

  if (editButton) {
    editButton.addEventListener("click", () => {
      if (editablePost.classList.contains("memory")) {
        const editingQueryParam = `memory-uuid=${editablePost.getAttribute("id")}`;
        window.location.href = `${props.memoriesUrl}?${editingQueryParam}`;
      } else if (editablePost.classList.contains("question")) {
        const editingQueryParam = `question-uuid=${editablePost.getAttribute("id")}`;
        window.location.href = `${props.questionsUrl}?${editingQueryParam}`;
      } else if (editablePost.classList.contains("comment")) {
        if (mainEditControls) {
          mainEditControls.classList.add("d-none");
        }

        if (confirmEditControlsContainer) {
          confirmEditControlsContainer.classList.remove("d-none");
        }

        if (toggleEditDropdownIcon) {
          toggleEditDropdownIcon.classList.add("d-none");
        }

        if (editField) {
          editField.classList.remove("d-none");
        }

        const userPostTextContainer = editablePost.querySelector(
          ".user-post-text-container",
        );
        if (userPostTextContainer) {
          userPostTextContainer.classList.add("d-none");
        }

        const userPostText = editablePost.querySelector(".user-post-text");
        if (userPostText) {
          userPostText.classList.add("d-none");
        }

        const errorList = editablePost.querySelector(".errorlist");
        if (errorList) {
          errorList.classList.add("d-none");
        }
      } else if (editablePost.classList.contains("post")) {
        const postActions = editablePost.querySelector(".post-actions");
        if (postActions) {
          postActions.classList.add("d-none");
        }

        const postContent = editablePost.querySelector(".post-content > p");
        if (postContent) {
          postContent.classList.add("d-none");
        }

        const editPost = editablePost.querySelector(".edit-post");
        if (editPost) {
          editPost.classList.remove("d-none");
        }

        const errorList = editablePost.querySelector(".errorlist");
        if (errorList) {
          postActions.classList.add("d-none");
        }

        confirmEditControlsContainer.classList.remove("d-none");
        toggleEditDropdownIcon.classList.add("d-none");
      } else if (editablePost.classList.contains("review-response")) {
        mainEditControls.classList.add("d-none");
        confirmEditControlsContainer.classList.remove("d-none");
        toggleEditDropdownIcon.classList.add("d-none");

        if (editField) {
          editField.classList.remove("d-none");
        }

        const userPostTextContainer = editablePost.querySelector(
          ".user-post-text-container",
        );
        if (userPostTextContainer) {
          userPostTextContainer.classList.add("d-none");
        }

        const userPostText = editablePost.querySelector(".user-post-text");
        if (userPostText) {
          userPostText.classList.add("d-none");
        }

        const errorList = editablePost.querySelector(".errorlist");
        if (errorList) {
          errorList.classList.add("d-none");
        }
      } else if (editablePost.classList.contains("question-container")) {
        const formContainer = editablePost.parentElement.querySelector(
          ".edit-question-form-container",
        );
        if (formContainer) {
          formContainer.classList.remove("d-none");
        }
      } else if (editablePost.classList.contains("answer-container")) {
        const formContainer = editablePost.querySelector(".edit-answer-form-container");
        if (formContainer) {
          formContainer.classList.remove("d-none");
        }
      }
    });
  }

  async function submitEditedPost(event) {
    // Hook into submit-protect.
    event.target.dataset.clicked = true;

    const restoreButton = handleAjaxFormSubmitProtection(event.target);

    // Handle posting.
    const isDiscussionPost = editablePost.classList.contains("post");
    const thisEditField = isDiscussionPost
      ? editablePost.querySelector("textarea")
      : editField;
    const text = thisEditField.value;

    const displayedComment = editablePost.querySelector(".user-post-text");

    if (!text) {
      const blankErrorMessage = editablePost.querySelector(
        ".edit-error-message-blank-submission",
      );
      if (blankErrorMessage) {
        blankErrorMessage.classList.remove("d-none");
      }

      if (isDiscussionPost && thisEditField) {
        thisEditField.value = displayedComment.innerText;
      }

      showMainEditState();
    } else {
      try {
        const response = await requests.post(confirmEditButton.dataset.editUrl, {
          text,
        });
        if (!response.ok) {
          throw new ResponseError(response);
        }
      } catch {
        const errorMessage = editablePost.querySelector(".edit-error-message-general");
        if (errorMessage) {
          errorMessage.classList.remove("d-none");
        }

        showMainEditState();

        if (restoreButton) {
          restoreButton();
        }

        return;
      }

      if (restoreButton) {
        restoreButton();
      }

      displayedComment.innerText = `${text}`;

      if (editField) {
        editField.value = `${text}`;
      }

      showMainEditState(true);
    }
  }

  if (confirmEditButton) {
    confirmEditButton.addEventListener("click", submitEditedPost);
  }

  if (editField) {
    editField.addEventListener("keydown", (event) => {
      if (event.keyCode === 13 && !event.metaKey) {
        submitEditedPost(event);
        return false;
      }
      return true;
    });
  }

  const cancelEdit = editablePost.querySelector(".cancel-edit-user-post");
  if (cancelEdit) {
    cancelEdit.addEventListener("click", () => {
      showMainEditState();
    });
  }

  const confirmDelete = editablePost.querySelector(".confirm-delete-user-post");
  if (confirmDelete) {
    editablePost.addEventListener("hidden.bs.collapse", () => {
      const commentContainer = editablePost.closest(".user-post-container");
      if (commentContainer) {
        const userPostHeader = commentContainer.querySelector(
          ".user-post-section-header",
        );
        confirmDelete.remove();
        editablePost.remove();

        // if last post is deleted make sure to hide the header too
        if (!commentContainer.querySelectorAll(".editable-user-post").length) {
          if (userPostHeader) {
            userPostHeader.classList.add("d-none");
          }
        }
      }
    });

    confirmDelete.addEventListener("click", async () => {
      let responseData;

      try {
        const response = await requests.delete(confirmDelete.dataset.deleteUrl);

        if (!response.ok) {
          throw new ResponseError(response);
        }

        responseData = await response.json();
      } catch {
        const editErrorMessage = editablePost.querySelector(
          ".edit-error-message-general",
        );
        if (editErrorMessage) {
          editErrorMessage.classList.remove("d-none");
        }
        showMainEditState();
      }

      if (responseData) {
        if (editablePost.classList.contains("post")) {
          const posterName = editablePost.querySelector(".poster-name");
          if (posterName) {
            posterName.innerText = "(Deleted)";
          }

          const postText = editablePost.querySelector(".user-post-text");
          if (postText) {
            while (postText.firstChild) {
              postText.removeChild(postText.firstChild);
            }

            const deletedMessage = document.createElement("span");
            deletedMessage.classList.add("deleted");
            deletedMessage.innerText = "This post has been deleted.";

            postText.appendChild(deletedMessage);
          }

          const editControls = editablePost.querySelector(".user-post-edit-controls");
          if (editControls) {
            editControls.remove();
          }
        } else {
          Collapse.getOrCreateInstance(editablePost, { toggle: false }).hide();
        }

        if (window.showBlockUserModal) {
          const { userSlug, userName } = confirmDelete.dataset;
          if (userSlug && userName) {
            window.showBlockUserModal(userSlug, userName);
          }
        }

        const { redirectUrl } = responseData;
        if (redirectUrl) {
          window.location.href = redirectUrl;
        }
      }
    });
  }
}

export function initEditableUserPosts(props) {
  document.querySelectorAll(".editable-user-post").forEach((editablePost) => {
    initEditableUserPost(editablePost, props);
  });
}
